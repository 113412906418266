import React from "react";
import ReactDOM from "react-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./msalConfig";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { App } from "App";
import { setMsalInstance } from "./services/apiService";

initializeIcons();

// MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
const msalInstance = new PublicClientApplication(msalConfig);
setMsalInstance(msalInstance);

ReactDOM.render(
  <React.StrictMode>
    <App instance={msalInstance} />
  </React.StrictMode>,
  document.getElementById("root"),
);
